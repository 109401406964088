@import '../../common.scss';

.container {
  @include flexDefaultStyle;

  > .categories {
    display: flex;
    flex: 1;

    @include tablet {
      flex-wrap: wrap;
    }
    @include mobile {
      flex-direction: column;
    }
    > li {
      @include flexDefaultStyle;
      align-items: flex-start;
      background-color: $main-blueColor;
      position: relative;
      width: 25%;

      &:hover {
        > .background {
          opacity: 0.5;
        }
      }
      @include tablet {
        width: 50%;
      }
      @include mobile {
        width: 100%;
      }
      > a {
        z-index: 1;
        padding: 3rem 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;

        > .title {
          @include font-en;
          color: #fff;
          font-size: 1.4rem;
          font-weight: 400;
          letter-spacing: 3px;
          position: relative;
          text-align: center;

          &::after {
            position: absolute;
            bottom: -25px;
            left: 50%;
            width: 24px;
            height: 3px;
            background-color: #fff;
            content: '';
            transform: translate(-50%, 0);
          }
        }
        > .items {
          margin-top: 2rem;

          @include mobile {
            margin-top: 2.5rem;
          }
          > li {
            text-align: center;
            font-size: 0.9rem;
            color: #fff;
            line-height: 1.8;
          }
        }
      }
      > .background {
        @include backgroundDefaultStyle;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transition: all 0.2s ease-in;
      }
    }
  }
}
