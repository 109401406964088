@import '../../common.scss';

.container {
  @include backgroundDefaultStyle;
  display: flex;
  justify-content: center;
  background-image: url('../../assets/images/company/global.jpg');
  padding: 4rem 0;

  > .limiter {
    @include limiter;

    > .title {
      @include normalTitleStyle;
    }
    > .text {
      @include mobile {
        text-align: center;
      }
      > .sub {
        max-width: 80%;
        font-size: 0.9rem;
        line-height: 1.6;

        @include mobile {
          max-width: initial;
        }
      }
    }
  }
}
