@import '../../common.scss';

.container {
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  text-align: center;

  > .limiter {
    @include limiter;

    > .title {
      @include normalTitleStyle;
    }
    > .welfares {
      display: flex;
      justify-content: space-between;

      @include mobile {
        flex-direction: column;
      }
      > li {
        flex: 1;
        position: relative;
        margin-left: 1rem;
        cursor: pointer;

        &:first-child {
          margin-left: 0;
        }
        &:hover {
          > .circle {
            opacity: 0.1;
          }
          > .content {
            opacity: 1;
          }
        }
        @include mobile {
          min-height: 14rem;
          margin-left: initial;

          &:not(:first-child) {
            margin-top: 1rem;
          }
        }
        > .circle {
          @include backgroundDefaultStyle;
          background-size: contain;
          min-height: 16rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          transition: all 0.2s ease-in;

          > .label {
            margin-top: 7rem;
            color: #fff;
            font-size: 1.125rem;
          }
          > .more {
            color: #fff;
            font-size: 1.25rem;
            margin-top: 0.5rem;
          }
        }
        > .content {
          position: absolute;
          left: 50%;
          top: 50%;
          opacity: 0;
          transform: translate(-50%, -50%);

          > li {
            line-height: 1.5;
            font-size: 1rem;
            white-space: nowrap;

            &.en {
              font-size: 0.75rem;

              > span {
                display: block;
                line-height: inherit;
              }
            }
            > span {
              font-size: 0.75rem;
            }
          }
        }
      }
    }
  }
}
