@import '../../common.scss';

$step1-color: #81a2c6;
$step2-color: #4274aa;
$step3-color: #08448e;

.container {
  padding: 4rem 0;
  display: flex;
  background-color: #e5ecf3;
  justify-content: center;

  > .limiter {
    @include limiter;

    > .title {
      @include normalTitleStyle;
    }
    > .label {
      color: $main-blueColor;
      font-size: 1.125rem;
      margin-bottom: 1rem;
    }
    > .table {
      background-color: white;

      > .row {
        @include flexAlignCenter;
        border-bottom: 1px solid #eee;

        @include mobile {
          flex-direction: column;
        }
        > .header {
          padding: 1rem;
          color: $main-blueColor;
          font-size: 1rem;
          width: 8rem;

          @include mobile {
            box-sizing: border-box;
            width: 100%;
            text-align: left;
            background-color: $main-lightGrayColor;
          }
        }
        > .body {
          flex: 1;
          border-left: 1px solid #eee;
          font-size: 1rem;
          box-sizing: border-box;
          padding: 1rem;
          line-height: 1.5;

          @include mobile {
            width: 100%;
          }
        }
      }
    }
    > .steps {
      display: flex;
      justify-content: space-between;

      @include tablet {
        align-items: center;
        flex-direction: column;
      }
      > li {
        @include backgroundDefaultStyle;
        background-size: contain;
        text-align: center;
        flex: 1;
        min-height: 12rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: relative;

        &::after {
          content: '►';
          width: 6rem;
          line-height: 2px;
          position: absolute;
          right: -3rem;
          bottom: 50%;
          transform: translate(0, -50%);
        }
        &:nth-child(1)::after {
          background-color: $step1-color;
          color: $step1-color;
        }
        &:nth-child(2)::after {
          background-color: $step2-color;
          color: $step2-color;
        }
        &:nth-child(3)::after {
          background-color: $step3-color;
          color: $step3-color;
        }
        &:last-child::after {
          display: none;
        }
        @include tablet {
          width: 100%;
          margin-bottom: 1rem;

          &::before {
            content: '▼';
            position: absolute;
            bottom: -1rem;
            left: calc(50% - 1px);
            transform: translate(-47%, 0);
            font-size: 0.8rem;
          }
          &::after {
            content: '';
            width: 1px;
            height: 4rem;
            right: 50%;
            bottom: -4rem;
            transform: translate(-50%, -50%);
          }
          &:nth-child(1)::before {
            color: $step1-color;
          }
          &:nth-child(2)::before {
            color: $step2-color;
          }
          &:nth-child(3)::before {
            color: $step3-color;
          }
          &:last-child::before {
            display: none;
          }
        }
        > .order {
          color: #fff;
          font-size: 1rem;
        }
        > .text {
          color: #fff;
          font-size: 0.8rem;
          line-height: 1.4;

          > span {
            display: block;
            line-height: inherit;
          }
        }
      }
    }
    > .support > a {
      font-size: 1rem;
      display: block;
      line-height: 1.6;

      @include mobile {
        font-size: 0.8rem;
      }
      > span {
        display: block;
        line-height: inherit;
      }
    }
  }
}
