@import '../../common.scss';

.container {
  display: flex;
  justify-content: center;

  > .limiter {
    @include limiter;
    @include flexAlignCenter;
    flex-direction: column;

    > img {
      padding: 6rem 0;
      width: 100%;

      &.desktop {
        display: block;
        max-width: 44rem;

        @include mobile {
          display: none;
        }
      }
      &.mobile {
        display: none;
        max-width: 18rem;

        @include mobile {
          display: block;
        }
      }
    }
    > .step {
      margin-bottom: 4rem;

      > li {
        @include backgroundDefaultStyle;
        margin-bottom: 2rem;
        padding: 2rem;
        box-sizing: border-box;

        > .info {
          > .box {
            max-width: 14rem;
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
            padding: 2rem;
            box-sizing: border-box;

            @include mobile {
              max-width: 100%;
            }
            > .character {
              @include font-en;
              font-size: 5rem;
              color: #fff;
            }
            > .title {
              @include font-en;
              padding: 0.5rem 0;
              color: #fff;
              font-size: 1.25rem;
            }
            > .sub {
              color: #fff;
              font-size: 1rem;
              line-height: 1.5;
            }
          }
          > .text {
            margin-top: 1rem;
            color: #fff;
            font-size: 0.9rem;
            line-height: 1.6;
            width: 40%;
            text-shadow: 2px 2px 10px rgba(black, 0.4);

            @include mobile {
              width: 100%;
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
