@import './common.scss';

* {
  margin: 0;
  padding: 0;
  line-height: 1;
  word-break: keep-all;
  font-family: 'Noto Sans KR', sans-serif;
}

html {
  font-size: 20px;

  @include tablet {
    font-size: 19px;
  }

  @include mobile {
    font-size: 18px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
a {
  text-decoration: none;
  color: #000;
}
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
